const addScript = (url, async = false) => {
  const script = document.createElement("script")
  script.src = url
  if (async) {
    script.async = true
  }
  document.getElementsByTagName(`head`)[0].appendChild(script)
}

exports.onRouteUpdate = s => {
  addScript(
    `//scripts.iconnode.com/${process.env.GATSBY_WHATCONVERTS_NUMBER}.js`
  )

  let qbloaded = false
  const qbload = () => {
    if (!qbloaded) {
      qbloaded = true
      setTimeout(() => {
        addScript("https://quotes.kinglimoinc.com/chat-script/14")
      }, 4000)
    }
  }

  // Assuming you don't have jQuery, here's how to replicate the event listeners
  document.addEventListener("mousemove", qbload)
  document.addEventListener("scroll", qbload)

  if (typeof window !== "undefined") {
    const observer = new MutationObserver((mutations, obs) => {
      const container = document.getElementById("ssiframecontainerbot")
      if (container) {
        console.log("Parent div found") // Verify that the div is found
        container.addEventListener(
          "mouseenter",
          () => {
            console.log("Mouse entered chatbot area") // Confirm mouse enters the area
            if (window.gtag) {
              window.gtag("event", "interaction", {
                event_category: "Chatbot",
                event_label: "Mouse Entered Chatbot Area",
                value: "1",
              })
            }
          },
          { once: true }
        )
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  }
}
